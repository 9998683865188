import React from "react"
import Container from "../../styles/styled-component/Container"
import { HeroSection } from "../../styles/styled-component/Section"
import InnerContainer, {
  HeroInnerContainer,
} from "../../styles/styled-component/InnerContainer"
import bgImg from "../../images/iso-black-divas.jpeg"

import styled from "styled-components"
import Button, { ButtonWrapper } from "../../styles/styled-component/Button"
import { StaticImage } from "gatsby-plugin-image"
import { Trans } from "gatsby-plugin-react-i18next"
import { useI18next } from "gatsby-plugin-react-i18next"
import { useState, useEffect } from "react"
export const HeroImage = styled.div`
  display: none;
  width: 100%;
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
`

const Home = () => {
  const { language } = useI18next()

  const [direction, setDirection] = useState(false)

  useEffect(() => {
    const changeDirection = () => {
      if (language == "ar") {
        setDirection(!direction)
      } else {
        setDirection(direction)
      }
    }
    changeDirection()
  }, [])

  return (
    <HeroSection
      bgImage={bgImg}
      id="home"
      bgColor="#f2f2f2"
      flex
      positionX={direction}
    >
      <Container flex center direction="row">
        <InnerContainer half centerText>
          <h1>Divas Lift </h1>

          <Trans>
            {" "}
            <p>
              <Trans>
                Italian made platform lift tailored for your home needs and
                style, in collaboration with Italian artisans to provide high
                quality solutions to match your home style
              </Trans>
            </p>
          </Trans>

          <ButtonWrapper centerButton>
            <Button
              to="/contact"
              bgColor="#0066FF"
              bgColorHover="#005ce6"
              borderRadius="24px"
            >
              <Trans>Contact Now</Trans>
            </Button>
          </ButtonWrapper>
        </InnerContainer>
        <HeroInnerContainer half>
          <HeroImage>
            <StaticImage
              height={500}
              src="../../images/iso-black-divas.jpeg"
              placeholder="blurred"
            ></StaticImage>
          </HeroImage>
        </HeroInnerContainer>
      </Container>
    </HeroSection>
  )
}
export default Home
