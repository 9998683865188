import React from "react"
import Container from "../../styles/styled-component/Container"
import Section from "../../styles/styled-component/Section"
import InnerContainer from "../../styles/styled-component/InnerContainer"
import Label from "../../styles/styled-component/Label"
import Button, { ButtonWrapper } from "../../styles/styled-component/Button"
import { StaticImage } from "gatsby-plugin-image"
import { Trans } from "gatsby-plugin-react-i18next"
const Explore = () => {
  return (
    <Section flex spaceBottom>
      <Container flex center spaceBottom>
        <InnerContainer half>
          <Label color="#25a18e">
            <Trans>Specifications</Trans>
          </Label>
          <h2>
            <Trans>Divas for your home</Trans>
          </h2>
          <p>
            <Trans>
              Learn about the different standard solutions we provide for your
              home lift, doesn't fit? Contact us to get a bespoke solution
            </Trans>
          </p>
          <ButtonWrapper>
            <Button
              to="/specifications"
              bgColor="#25a18e"
              bgColorHover="#218676"
              borderRadius="24px"
            >
              <Trans>Go to Specs</Trans>
            </Button>
          </ButtonWrapper>
        </InnerContainer>
        <InnerContainer half>
          {" "}
          <StaticImage
            src="../../images/DivasErica.jpeg"
            placeholder="blurred"
          ></StaticImage>
        </InnerContainer>
      </Container>
      <Container flex center direction="row-reverse">
        <InnerContainer half>
          <Label color="#0066FF">
            <Trans>Customize design</Trans>
          </Label>
          <h2>
            <Trans>Find your style</Trans>
          </h2>
          <p>
            <Trans>
              Discover the different customization options we provide for Divas
              Lift, and learn about the <strong>Luxury line</strong> made with
              valuable handcrafted materials
            </Trans>
          </p>{" "}
          <ButtonWrapper>
            <Button
              to="/design"
              bgColor="#0066FF"
              bgColorHover="#005ce6"
              borderRadius="24px"
            >
              <Trans>Design your Divas</Trans>
            </Button>
          </ButtonWrapper>
        </InnerContainer>
        <InnerContainer half>
          <StaticImage
            src="../../images/Divaus-open.jpeg"
            placeholder="blurred"
          ></StaticImage>
        </InnerContainer>
      </Container>
    </Section>
  )
}

export default Explore
