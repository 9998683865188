import React from "react"

import { FaSolarPanel } from "react-icons/fa"
import { BsGlobe2 } from "react-icons/bs"
import { BiAccessibility } from "react-icons/bi"
import {
  IconContainer,
  IconP,
  Icon,
  IconWrapper,
  IconSection,
  IconButton,
} from "./homeElements"
import { Trans } from "gatsby-plugin-react-i18next"
const IconRow = () => {
  return (
    <IconSection>
      <IconWrapper flex>
        <IconContainer>
          <Icon>
            <BsGlobe2 />
          </Icon>
          <IconP>
            <IconButton to="/global-presence" borderRadius="24px">
              {" "}
              <Trans>Global Presence</Trans>
            </IconButton>
          </IconP>
        </IconContainer>{" "}
        <IconContainer colorGreen>
          <Icon>
            {" "}
            <FaSolarPanel />
          </Icon>
          <IconP>
            <IconButton to="/zero-energy" borderRadius="24px">
              <Trans>Zero Energy</Trans>
            </IconButton>
          </IconP>
        </IconContainer>{" "}
        <IconContainer>
          <Icon>
            {" "}
            <BiAccessibility />
          </Icon>{" "}
          <IconP>
            {" "}
            <Trans>Full accessibility</Trans>
          </IconP>
        </IconContainer>
      </IconWrapper>
    </IconSection>
  )
}

export default IconRow
