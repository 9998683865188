import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Home from "../components/home/Home"
import Explore from "../components/home/Explore"
import Endpage from "../components/home/EndPage"
import IconRow from "../components/home/IconRow"
import { graphql } from "gatsby"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Home></Home>
    <IconRow />
    <Explore></Explore>
    <Endpage></Endpage>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
