import React, { useState } from "react"

import { EndPageContainer } from "../../styles/styled-component/Container"
import Section from "../../styles/styled-component/Section"
import { BiMailSend } from "react-icons/bi"
import { Button } from "../../styles/styled-component/Button"
import "../../styles/typography.css"
import { Trans } from "gatsby-plugin-react-i18next"
const EndPage = () => {
  const [hover, setHover] = useState(false)
  const onHover = () => {
    setHover(!hover)
  }

  return (
    <Section flex bgColor="#f2f2f2">
      <EndPageContainer flex center direction="column">
        <BiMailSend size={124} />
        <h1 className="end-page-title">
          <Trans>Get in touch</Trans>
        </h1>
        <p>
          <Trans>
            Fill a inquiry or book an appointment with our sales team
          </Trans>
        </p>
        <Button
          to="/contact"
          bgColor="#0066FF"
          bgColorHover="#005ce6"
          borderRadius="24px"
          onMouseEnter={onHover}
          onMouseLeave={onHover}
        >
          <Trans>ASK US</Trans>
        </Button>
      </EndPageContainer>
    </Section>
  )
}

export default EndPage
